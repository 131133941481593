* {
    -webkit-overflow-scrolling: touch;
}

body {
    background: rgba(247, 247, 249, 1);
}

.premium-with-image,
.premium-with-image-web {
    background-image: url("./img/mobile-premium-image-1.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 20px;
    height: 445px;
    border-radius: 20px;
    width: 100%;
}

.premium-with-image-web {
    background-image: url("./img/web-premium-image.png");
    padding-top: 10px;
    height: 80vh;
}

.home-page {
    height: 86vh;
}


@media (min-width: 1120px) {
    .home-page {
        height: 86vh;
    }
}

.thumbs-up-animation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
    animation: moveUp 4s forwards;
}

.custom-quiz-margin {
    margin-top: -70px;
}

@keyframes moveUp {
    from {
        top: 50%;
        opacity: 1;
    }
    to {
        top: 0;
        opacity: 0;
    }
}

@keyframes slideLeft {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slideBottom {
    from {
        transform: translateY(-15%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.animate-slideBottom {
    animation: slideBottom 250ms ease-out;
}

.animate-slideLeft {
    animation: slideLeft 1s ease-out forwards;
}


h1 {
    text-wrap: balance;
}

.react-md p {
    margin: 5px 0;
}

.react-md h1,
.react-md h2,
.react-md h3,
.react-md h4,
.react-md h5 {
    font-weight: bold;
    margin: 5px 0;
}

.react-md h1 {
    font-size: 2em;
    margin-top: 10px;
}

.react-md h2 {
    margin-top: 15px;
    font-size: 1.5em;
}

.react-md h3 {
    font-size: 1.17em;
    margin-top: 10px;
}

.react-md h4 {

}

.react-md a {
    color: #074fed;
}


.quiz_navbar {
    background-image: url("./img/quiz_navbar.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.home_navbar {
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 359deg);
    background-repeat: no-repeat;
    background-size: cover;
}

.quiz_page_two_h1 {
    color: #1d1c1f;
}

.quiz_page_two_h2 {
    color: #1d1c1f;
}

.quiz_page_two_h3 {
    color: rgba(29, 29, 31, 0.5);
}

.quiz_page_two_form_card {
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(127, 54, 247, 0.6);
    border-radius: 10px;
    width: 178px;
    height: 86px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    color: #7f36f7;
    padding: 10px 4px;
    margin: 0 6px;
    cursor: pointer;
    flex: 0 0 calc(33.333% - 20px);
    margin: 10px;
}

.quiz_page_two_form_card.active {
    background: rgba(235, 222, 255, 0.4);
    border-radius: 10px;
    box-shadow: 0 0 0 1px #784ace;
    border-color: #784ace;
}

.quiz_page_two_form_card.active .number {
    position: absolute;
    width: 20px;
    border-radius: 50%;
    background-color: #784ace;
    color: white;
    top: -9px;
    left: -9px;
}

.quiz_back_button {
    left: 35px;
    top: 15px;
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='35' height='35' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='48' height='48' rx='12' fill='%23F7F7F9'/%3E%3Cmask id='mask0_1211_25488' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='12' y='12' width='24' height='24'%3E%3Crect x='12' y='12' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1211_25488)'%3E%3Cpath d='M24 32L16 24L24 16L25.425 17.4L19.825 23H32V25H19.825L25.425 30.6L24 32Z' fill='%231D1D1F' fill-opacity='0.5'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left top;
    padding-left: calc(48px + 16px);
    line-height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #525258;
    cursor: pointer;
}

.quiz_back_button2 {
    left: 35px;
    top: 15px;
    width: 35px;
    height: 35px;
    background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='48' height='48' rx='12' fill='%23F7F7F9'/%3E%3Cmask id='mask0_1211_25488' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='12' y='12' width='24' height='24'%3E%3Crect x='12' y='12' width='24' height='24' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_1211_25488)'%3E%3Cpath d='M24 32L16 24L24 16L25.425 17.4L19.825 23H32V25H19.825L25.425 30.6L24 32Z' fill='%231D1D1F' fill-opacity='0.5'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 30px 30px;
    padding-left: calc(60px + 16px);
    line-height: 48px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #525258;
    cursor: pointer;
}


.button_bg {
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
    border-radius: 12px;
}

.quiz_form_button {
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
    border-radius: 12px;
    width: 178px;
    height: 52px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
}

.quiz_form_submition {
    margin-top: 0;
}

.quiz_form_button:disabled {
    background: rgb(128 128 128 / 24%);
}

.visible_1 {
    opacity: 1;
    animation-name: pageShow;
    animation-duration: 1s;
    transition: ease-in-out;
}

.visibleDropdown {
    opacity: 1;
    animation-name: dropdown;
    animation-duration: 1s;
    transition: ease-in-out;
}


@keyframes dropdown {
    from {
        opacity: 0;
        top: 40px;
    }
    to {
        opacity: 1;
        top: 0;
    }
}


@keyframes pageShow {
    from {
        opacity: 0;
        top: -100px;
    }
    to {
        opacity: 1;
        top: 0;
    }
}

.brand_me_up_and_quiz_button {
    background-color: red;
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 363.61deg);
}

.quiz_sidebar_content {
    background: rgba(247, 247, 249, 1);
}

.sidebar_link_text_disabled {
    color: rgb(128, 128, 128);
    cursor: default;
}

.character {
    border: none;
    font-size: 20px;
    border-radius: 8px;
    width: 80px;
    color: #272729;
    background-color: #f6f5fa;
    box-shadow: 0 2px 0 #e4e2f5;
}

.branda_result {
    width: 1000px;
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
    border-radius: 24px;
}

.branda_result_page_2 {
    width: 1000px;
    /* height: 740px; */
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
    border-radius: 24px;
}

.branda_thank_you_page {
    width: 600px;
    border-radius: 24px;
}

.finish-quiz-middle-box {
    background: rgba(29, 29, 31, 0.3);
    color: rgba(255, 255, 255, 0.7);
}

.finish_bottom_text {
    background: rgba(29, 29, 31, 0.3);
}

.finish_bottom_text_twelve {
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
}

.button_color_purple {
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
}

.bordered-question {
    border-radius: 5px;
}

.page_twelve_text_share {
    color: #552bb1;
}

.page_twelve_text_invest {
    color: #552bb1;
}

.vi__wrapper {
    width: 100%;
}

.quiz_steps {
    height: 24px;
    position: relative;
}

.quiz_steps_step {
    background: #fff;
    padding: 5px 15px;
    border-bottom: 2px solid transparent;
}

.quiz_steps_step.active {
    font-weight: bold;
    color: rgba(85, 43, 177);
    border-bottom-color: rgba(85, 43, 177);
}

.text-mobile-lg {
    font-size: 18pt;
}

.genie_quiz label {
    display: flex;
    justify-content: space-between;
}

.last_step label {
    display: flex;
    justify-content: space-between;
}

.last_step_1 label {
    display: flex;
    justify-content: space-between;
}

.last_step_1 label span:nth-child(2),
.last_step_2 label span:nth-child(2) {
    width: 100%;
    text-align: center;
}

.last_step_1 {
    width: 100%;
    justify-content: space-around;
}

.last_step_2 {
    justify-content: space-around;
    width: 100%;
}

.last_step_2 label {
    display: flex;
    justify-content: space-between;
}

.scroller {
    height: 2em;
    line-height: 1.2em;
    width: 35em;
}

.support {
    background-color: rgba(255, 255, 255, 0.5);
    background-image: conic-gradient(
            at 0% 100%,
            rgba(137, 147, 255, 0.166) 10deg,
            rgba(244, 102, 147, 0.148) 77deg,
            rgba(254, 188, 73, 0.168) 95deg
    );
}

@media only screen and (max-width: 900px) {
    .scroller {
        height: 1.2em;
        line-height: 1.2em;
        width: 35em;
    }

    .quiz_navbar {
        background-image: url("./img/quiz_navbar_mobile.png");
    }

}

.scroller > span {
    animation: slide 11s infinite;
}

.chat-modal {
    position: fixed;
    bottom: 8rem;
    right: 1rem;
    /* transform: translate(-50%, -50%); */
    z-index: 1000;
    /* animation: slideout 0.5s forwards; */
}

@keyframes slideout {
    from {
        opacity: 0;
        transform: translate(-50%, -50%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, -60%);
    }
}

@media only screen and (max-width: 600px) {

    .quiz_form_button {
        margin-top: 50px;
    }

    .character {
        font-size: 16px;
    }

    .home-page {
        height: 67vh;
    }

    .chat-modal {
        bottom: 4rem;
    }

    .home_navbar {
        background-size: 100% 100%;
        background-position: inherit;
    }

    .last_step_first_form {
        width: 100%;
        flex-direction: column !important;
        gap: 0;
    }

    .radio-group-page-six {
        flex-direction: column !important;
    }

    .quiz_page_two_form_card {
        flex: 0 0 calc(50% - 20px);
    }

    .quiz-four-text {
        width: auto;
    }

    .text-mobile-lg {
        font-size: 14pt;
    }

    .home_footer_text {
        font-size: 13px;
    }

    .quiz_page_three_form_button {
        font-size: 13px;
    }

    .quiz_page_two_form_button {
        font-size: 16px;
    }

    .scroller {
        width: 100%;
    }

    .scroller > span {
        animation: slide-mobile 11s infinite;
    }

    @keyframes slide-mobile {
        0% {
            top: 0;
        }
        20% {
            top: 0;
        }
        30% {
            top: -1.5em;
        }
        40% {
            top: -1.5em;
        }
        50% {
            top: -1.5em;
        }
        60% {
            top: -3em;
        }
        70% {
            top: -3em;
        }
        80% {
            top: -3em;
        }
        100% {
            top: 0;
        }
    }
}

@media only screen and (max-width: 475px) {
    .scroller > span {
        animation: slide-mobile-min 11s infinite;
    }

    @keyframes slide-mobile-min {
        0%, 15% {
            top: 0;
        }
        30% {
            top: -1.5em;
        }
        50% {
            top: -3em;
        }
        60% {
            top: -4.2em;
        }
        70% {
            top: -5.8em;
        }
    }
}

@keyframes slide {
    0% {
        top: 0;
    }
    20% {
        top: 0;
    }
    30% {
        top: -1.5em;
    }
    40% {
        top: -1.5em;
    }
    50% {
        top: -1.5em;
    }
    60% {
        top: -3em;
    }
    70% {
        top: -3em;
    }
    80% {
        top: -3em;
    }
    100% {
        top: 0;
    }
}

.header-big-text {
    font-size: 180px;
    line-height: 1;
}

/*  HEADER */
.main_header_home {
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 363.61deg);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}

.menuhome:hover {
    background-color: black;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
}


.brand_me_up_button {
    width: 180px;
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 363.61deg);
}

.change_plan_button {
    background-color: red;
    background-image: conic-gradient(
            at -2% 100%,
            rgba(137, 147, 255, 1) 20deg,
            rgba(244, 102, 146, 1) 73deg,
            rgba(254, 189, 73, 1) 90deg
    );
}

.hamburger-icon {
    font-size: 24px;
    cursor: pointer;
}

.hamburger-icon:hover {
    color: #ccc;
}

.sign_in_sign_up {
    background: var(
            --gradient-III,
            linear-gradient(178deg, #fffeff 3.65%, #faf1fe 18.41%, #ebf1fe 104.1%)
    );
    width: 100vw;
}

.branda-score {
    background: var(
            --gradient-III,
            linear-gradient(178deg, #fffeff 3.65%, #faf1fe 18.41%, #ebf1fe 104.1%)
    );
}

.sign_in_button {
    background-color: transparent;
    width: 180px;
}

.sign_in_button_radius {
    padding: 1.5px;
}

.swiper-wrapper {
    margin-top: 10px;
}

.swiper-button-next,
.swiper-button-prev {
    color: #552bb1 !important;
    margin: -17px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 22px !important;
    margin-top: -350px;
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    background: #552bb1 !important;
}

.password_reset_confirmation {
    background: var(--gradient-III, linear-gradient(178deg, #FFFEFF 3.65%, #FAF1FE 18.41%, #EBF1FE 104.1%));
}

@media (max-width: 768px) {

    .quiz_form_submition {
        margin-top: 20px !important;
    }

    .weekly_plan_button {
        background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
        border-radius: 8px;
        width: 150px;
        height: 30px;
    }

    .contact-card {
        background-color: transparent;
    }

    .contact-card img {
        width: 50px;
        height: 40px;
    }

    .contact-card p {
        margin-top: 0.2rem;
        font-size: small;
        padding-top: 0%;
        color: #525258;
        font-weight: 500;
    }

    .test-class span {
        font-size: 15px;
    }
}

@media only screen and (max-width: 325px) {
    .contact-icons-wrapper {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.weekly_plan_button {
    background: linear-gradient(105.77deg, #8847bf -5.14%, #552bb1 102.98%);
    padding: 15px;
    border-radius: 8px;
    width: 250px;
    height: 35px;
    font-size: 16px;
    font-weight: 500;
    font-style: normal;
    text-align: center;
}

.brand_me_up_button {
    background-color: red;
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 363.61deg);
}

.chat_box_icon {
    z-index: 10;
    width: 52px;
    height: 52px;
    position: absolute;
    border-radius: 200px;
    background-image: url("img/chatBox.svg");
}

.about-us-center-div {
    background-image: url("img/bg-white-about-us.png");
}

.about-us-bottom-div {
    background-image: url("img/about-us-bottom.png");
}

.hover-icon {
    transition: transform 0.3s ease-in-out;
}

.hover-icon:hover {
    animation: moveAndRotate 1s infinite;
}

.circular_page_eight {
    background: var(
            --gradient-III,
            linear-gradient(178deg, #fffeff 3.65%, #faf1fe 18.41%, #ebf1fe 104.1%)
    );
}

.branda_score {
    background: conic-gradient(
            from 103.9deg at -4.17% 100%,
            #f46692 -19.61deg,
            #febd49 3.15deg,
            #8993ff 267.93deg,
            #f46692 340.39deg,
            #febd49 363.15deg
    );
    border-radius: 24px;
    padding: 32px;
}

@keyframes moveAndRotate {
    0%,
    100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(45deg);
    }
}


.small-text {
    font-size: 10px;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}


.rtl {
    direction: rtl;
}


.video-iframe {
    width: 100%;
    height: 610px;
}


@media (min-width: 768px) {
    .video-iframe {
        max-width: 1311px;
        height: 677px;
    }
}


@media (max-width: 768px) {
    .header-big-text {
        font-size: 120px !important;
        line-height: 1;
    }
}


.arrow-down {
    animation: MoveUpDown 1s linear infinite;
}

.nav-link {
    transition: 100ms ease;
}

@keyframes MoveUpDown {
    0%, 100% {
        top: 0;
    }
    50% {
        top: -10px;
    }
}


.business_selection_button {
    background: rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    width: 178px;
    height: 86px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    padding: 10px 4px;
    cursor: pointer;
    flex: 0 0 calc(33.333% - 20px);
    margin: 10px;
}

.business_selection_button.active {
    background: rgba(235, 222, 255, 0.4);
    border-radius: 10px;
    border-width: 2px;
    border-color: #784ace;
    color: #784ace;
}

.premium-plan {
    background: #F60085;
    border: 1px solid #000000;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 10px;
    box-sizing: border-box;
}

.other-plans {
    background: #fff;
    border: 1px solid #6333B5;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    box-sizing: border-box;
}

.plan-name {
    box-sizing: border-box;
    background: #F1F1F1;
    border: 1px solid #B9BABD;
}

.sidebar-upgrade {
    background: conic-gradient(from 90deg at -4.17% 100%, #F46692 -19.61deg, #FEBD49 3.61deg, #8993FF 267.93deg, #8993FF 279.01deg, #F46692 340.39deg, #FEBD49 363.61deg);
}

.sidebar-upgrade-mobile {
    background: conic-gradient(from 90deg at -4.17% 100%, #F40DB3 -19.61deg, #ED9903 3.61deg, #8993FF 267.93deg, #3241ED 279.01deg, #F40DB3 340.39deg, #ED9903 363.61deg);
}

.suggest-button {
    background-image: url("./img/mobile-premium-image.png");
    position: relative;
}

.suggest-button:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(246, 0, 133, 0.6);
    border-radius: 8px;
}

.suggest-button:hover:after {
    background-color: rgba(246, 0, 133, 0.5);
}

.banner {
    position: absolute;
    bottom: -15px;
    background-color: #F60085;
    color: #fff;
    padding: 0px 3px;
    font-size: 8px;
    text-align: center;
    z-index: 10;
    width: 57px;
}

.coming-soon {
    padding: 3px 5px;
    border-radius: 5px;
    font-size: 8px;
    margin-left: 2px;
    white-space: nowrap;
    background-color: #000000;
    color: #fff;
}

.premium-plan .coming-soon {
    background-color: white;
    color: #000;
}

/* REACT JOYRIDE */
.react-joyride__tooltip {
    border-radius: 10px;
}

.react-joyride__tooltip button[data-test-id=button-primary] {
    background-color: #784ace !important;
    color: #fff !important;
}

.react-joyride__tooltip button[data-test-id=button-back] {
    color: #784ace !important;
}

.react-joyride__beacon > span:first-of-type {
    background-color: #784ace !important;
}

.react-joyride__beacon > span:last-of-type {
    border-color: #784ace !important;
    background-color: rgba(120, 74, 206, 0.25) !important;
}

